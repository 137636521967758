<template>
  <div :style="style">
    <div v-if="orderLoading" class="w-full min-h-240 flex items-center">
        <div class="w-full md:text-center">
          <div
            class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-32 w-32 inline-block mb-24 max-w-1/2 mx-auto"
          />
        </div>
      </div>
      <div v-else>
        <BlocksContentArea
          v-for="(block, index) in blocks"
          :key="'MarketLandingPage-' + block?.ContentLink?.Expanded?.__typename + '-' + index"
          :block="block?.ContentLink?.Expanded"
          :text-color="page.TakeOverColorSettings?.TakeOverTextColor || ''"
          :data-epi-edit="'MainContent'"
        />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { type MarketLandingPageFragment } from '#gql';
import { useCartStore } from '~/store/cart';
import * as Sentry from '@sentry/vue'
import PopUpTypes from '~/constants/pop-up-types';
import { storeToRefs } from 'pinia';
const globalContent = useGlobalContentStore();
const { requestVerificationToken } = storeToRefs(globalContent);
const { apiGet } = useApiFetch();

const cartStore = useCartStore();
const orderLoading = ref(false);

const props = defineProps<{
  page: MarketLandingPageFragment,
  isMobile: boolean,
  isEditMode: boolean
}>();

const blocks = computed(() => {
  return (
    props.page.MainContent?.filter(
      (block) => block?.ContentLink?.Expanded !== null
    ) || []
  );
});

const style = computed(() => {
  if (props.page.TakeOverColorSettings?.TakeOverBackgroundColor) {
    return `background-color: ${props.page.TakeOverColorSettings?.TakeOverBackgroundColor}`;
  }
  return '';
});

onMounted(async() => {
  if (!globalContent.getTheAntiForgeryToken) {
    const result = await apiGet<string>(
      `website/xsrf/token`
    );
    if (result) {
      requestVerificationToken.value = result;
    }
  }

  nextTick(async() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    let orderNumber = params.get('orderNo') as string;

    if (process.client && orderNumber){
      try {
        orderLoading.value = true;
        orderNumber = atob(orderNumber);
        
        const success = await cartStore.repurchaseOrder({ orderNumber });

        if (success){
          const { $event } = useNuxtApp();

          const popUpType = PopUpTypes.Basket;
          $event('open-pop-up', {
            type: popUpType,
            data: {},
          })
        }
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        orderLoading.value = false;
      }
    }
  });
});


</script>
